exports.components = {
  "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-anonymous-association-mdx": () => import("./../../../src/components/templates/Column/index.tsx?__contentFilePath=/opt/build/repo/content/column/anonymous-association.mdx" /* webpackChunkName: "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-anonymous-association-mdx" */),
  "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-benefits-of-pool-mdx": () => import("./../../../src/components/templates/Column/index.tsx?__contentFilePath=/opt/build/repo/content/column/benefits-of-pool.mdx" /* webpackChunkName: "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-benefits-of-pool-mdx" */),
  "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-compare-with-time-deposits-mdx": () => import("./../../../src/components/templates/Column/index.tsx?__contentFilePath=/opt/build/repo/content/column/compare-with-time-deposits.mdx" /* webpackChunkName: "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-compare-with-time-deposits-mdx" */),
  "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-diversified-investment-mdx": () => import("./../../../src/components/templates/Column/index.tsx?__contentFilePath=/opt/build/repo/content/column/diversified-investment.mdx" /* webpackChunkName: "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-diversified-investment-mdx" */),
  "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-fixed-yield-investments-mdx": () => import("./../../../src/components/templates/Column/index.tsx?__contentFilePath=/opt/build/repo/content/column/fixed-yield-Investments.mdx" /* webpackChunkName: "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-fixed-yield-investments-mdx" */),
  "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-howto-smart-use-of-pool-mdx": () => import("./../../../src/components/templates/Column/index.tsx?__contentFilePath=/opt/build/repo/content/column/howto-smart-use-of-pool.mdx" /* webpackChunkName: "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-howto-smart-use-of-pool-mdx" */),
  "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-suitable-investment-period-mdx": () => import("./../../../src/components/templates/Column/index.tsx?__contentFilePath=/opt/build/repo/content/column/suitable-investment-period.mdx" /* webpackChunkName: "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-suitable-investment-period-mdx" */),
  "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-user-interview-01-mdx": () => import("./../../../src/components/templates/Column/index.tsx?__contentFilePath=/opt/build/repo/content/column/user-interview-01.mdx" /* webpackChunkName: "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-user-interview-01-mdx" */),
  "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-various-payment-cards-mdx": () => import("./../../../src/components/templates/Column/index.tsx?__contentFilePath=/opt/build/repo/content/column/various-payment-cards.mdx" /* webpackChunkName: "component---src-components-templates-column-index-tsx-content-file-path-opt-build-repo-content-column-various-payment-cards-mdx" */),
  "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-policy-aml-cft-mdx": () => import("./../../../src/components/templates/PublicMatters/index.tsx?__contentFilePath=/opt/build/repo/content/policy/aml-cft.mdx" /* webpackChunkName: "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-policy-aml-cft-mdx" */),
  "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-policy-antisocial-mdx": () => import("./../../../src/components/templates/PublicMatters/index.tsx?__contentFilePath=/opt/build/repo/content/policy/antisocial.mdx" /* webpackChunkName: "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-policy-antisocial-mdx" */),
  "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-policy-complaint-mdx": () => import("./../../../src/components/templates/PublicMatters/index.tsx?__contentFilePath=/opt/build/repo/content/policy/complaint.mdx" /* webpackChunkName: "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-policy-complaint-mdx" */),
  "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-policy-conflict-of-interest-mdx": () => import("./../../../src/components/templates/PublicMatters/index.tsx?__contentFilePath=/opt/build/repo/content/policy/conflict-of-interest.mdx" /* webpackChunkName: "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-policy-conflict-of-interest-mdx" */),
  "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-policy-privacy-mdx": () => import("./../../../src/components/templates/PublicMatters/index.tsx?__contentFilePath=/opt/build/repo/content/policy/privacy.mdx" /* webpackChunkName: "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-policy-privacy-mdx" */),
  "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-policy-privacy-prerelease-mdx": () => import("./../../../src/components/templates/PublicMatters/index.tsx?__contentFilePath=/opt/build/repo/content/policy/privacy-prerelease.mdx" /* webpackChunkName: "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-policy-privacy-prerelease-mdx" */),
  "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-policy-security-mdx": () => import("./../../../src/components/templates/PublicMatters/index.tsx?__contentFilePath=/opt/build/repo/content/policy/security.mdx" /* webpackChunkName: "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-policy-security-mdx" */),
  "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-policy-solicitation-mdx": () => import("./../../../src/components/templates/PublicMatters/index.tsx?__contentFilePath=/opt/build/repo/content/policy/solicitation.mdx" /* webpackChunkName: "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-policy-solicitation-mdx" */),
  "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-terms-electromagnetic-means-delivery-agreement-mdx": () => import("./../../../src/components/templates/PublicMatters/index.tsx?__contentFilePath=/opt/build/repo/content/terms/electromagnetic-means-delivery-agreement.mdx" /* webpackChunkName: "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-terms-electromagnetic-means-delivery-agreement-mdx" */),
  "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-terms-fiea-mdx": () => import("./../../../src/components/templates/PublicMatters/index.tsx?__contentFilePath=/opt/build/repo/content/terms/fiea.mdx" /* webpackChunkName: "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-terms-fiea-mdx" */),
  "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-terms-not-antisocial-forces-agreement-mdx": () => import("./../../../src/components/templates/PublicMatters/index.tsx?__contentFilePath=/opt/build/repo/content/terms/not-antisocial-forces-agreement.mdx" /* webpackChunkName: "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-terms-not-antisocial-forces-agreement-mdx" */),
  "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-terms-service-20220901-mdx": () => import("./../../../src/components/templates/PublicMatters/index.tsx?__contentFilePath=/opt/build/repo/content/terms/service/20220901.mdx" /* webpackChunkName: "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-terms-service-20220901-mdx" */),
  "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-terms-service-20230403-mdx": () => import("./../../../src/components/templates/PublicMatters/index.tsx?__contentFilePath=/opt/build/repo/content/terms/service/20230403.mdx" /* webpackChunkName: "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-terms-service-20230403-mdx" */),
  "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-terms-service-20240522-mdx": () => import("./../../../src/components/templates/PublicMatters/index.tsx?__contentFilePath=/opt/build/repo/content/terms/service/20240522.mdx" /* webpackChunkName: "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-terms-service-20240522-mdx" */),
  "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-terms-service-index-mdx": () => import("./../../../src/components/templates/PublicMatters/index.tsx?__contentFilePath=/opt/build/repo/content/terms/service/index.mdx" /* webpackChunkName: "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-terms-service-index-mdx" */),
  "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-terms-settlement-mdx": () => import("./../../../src/components/templates/PublicMatters/index.tsx?__contentFilePath=/opt/build/repo/content/terms/settlement.mdx" /* webpackChunkName: "component---src-components-templates-public-matters-index-tsx-content-file-path-opt-build-repo-content-terms-settlement-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-previous-ad-tsx": () => import("./../../../src/pages/about-previous-ad.tsx" /* webpackChunkName: "component---src-pages-about-previous-ad-tsx" */),
  "component---src-pages-app-vandle-fund-overview-tsx": () => import("./../../../src/pages/app/vandle-fund-overview.tsx" /* webpackChunkName: "component---src-pages-app-vandle-fund-overview-tsx" */),
  "component---src-pages-campaign-investment-202311-tsx": () => import("./../../../src/pages/campaign/investment-202311.tsx" /* webpackChunkName: "component---src-pages-campaign-investment-202311-tsx" */),
  "component---src-pages-campaign-investment-202405-tsx": () => import("./../../../src/pages/campaign/investment-202405.tsx" /* webpackChunkName: "component---src-pages-campaign-investment-202405-tsx" */),
  "component---src-pages-campaign-investment-202409-tsx": () => import("./../../../src/pages/campaign/investment-202409.tsx" /* webpackChunkName: "component---src-pages-campaign-investment-202409-tsx" */),
  "component---src-pages-campaign-reinvestment-202409-tsx": () => import("./../../../src/pages/campaign/reinvestment-202409.tsx" /* webpackChunkName: "component---src-pages-campaign-reinvestment-202409-tsx" */),
  "component---src-pages-column-tsx": () => import("./../../../src/pages/column.tsx" /* webpackChunkName: "component---src-pages-column-tsx" */),
  "component---src-pages-creditcard-tsx": () => import("./../../../src/pages/creditcard.tsx" /* webpackChunkName: "component---src-pages-creditcard-tsx" */),
  "component---src-pages-email-authorization-complete-error-tsx": () => import("./../../../src/pages/email-authorization-complete/error.tsx" /* webpackChunkName: "component---src-pages-email-authorization-complete-error-tsx" */),
  "component---src-pages-email-authorization-complete-success-tsx": () => import("./../../../src/pages/email-authorization-complete/success.tsx" /* webpackChunkName: "component---src-pages-email-authorization-complete-success-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investment-index-tsx": () => import("./../../../src/pages/investment/index.tsx" /* webpackChunkName: "component---src-pages-investment-index-tsx" */),
  "component---src-pages-investment-vandle-tsx": () => import("./../../../src/pages/investment/vandle.tsx" /* webpackChunkName: "component---src-pages-investment-vandle-tsx" */),
  "component---src-pages-maintenance-tsx": () => import("./../../../src/pages/maintenance.tsx" /* webpackChunkName: "component---src-pages-maintenance-tsx" */)
}

